import React from "react";
import styles from "./styles.module.css";
import bg from "../../assets/img/project-shield.jpg";
import moment from "moment";

import { request } from "../../utils";

export default () => {
  const [listLoading, setListLoading] = React.useState(false);
  const [current, setCurrent] = React.useState(0);
  const [data, setData] = React.useState([]);

  const reload = React.useCallback(async () => {
    setListLoading(true);
    const response = await request(`post/all`);
    if (response.type === "success") {
      setData(response.payload.data);
    } else {
      setData([]);
    }
    setListLoading(false);
  }, []);

  React.useEffect(() => {
    reload();
  }, [reload]);
  return (
    <div
      className={`${styles.about} wwc-content`}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className={styles.nav}>
        {data.map((item, i) => (
          <div key={i} className={styles.navItem} onClick={() => setCurrent(i)}>
            {item.name}
          </div>
        ))}
      </div>
      <div className={styles.content}>
        {data.map((item, i) => (
          <div className={styles.rowItem} key={i}>
            <div className={styles.headline}>
              <div>{item.title}</div>
            </div>
            <div
              className={styles.body}
              dangerouslySetInnerHTML={{ __html: item.body }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
