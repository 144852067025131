import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import storage from 'localforage';
import createRootReducer from './reducers';

const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: []
};

export default preloadedState => {
  const middlewares = [thunk];

  const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  middlewares.push(routerMiddleware(history));

  const store = createStore(
    persistReducer(persistConfig, createRootReducer(history)),
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  const persistor = persistStore(store);

  return { store, persistor, history };
};
