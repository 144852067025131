import React from "react";
import styles from "./styles.module.css";
import bg from "../../assets/img/map.png";
import logo from "../../assets/img/logo-white.png";
import { FacebookOutlined, TwitterOutlined } from "@ant-design/icons";
export default () => {
  return (
    <div className={styles.home} style={{ backgroundImage: `url(${bg})` }}>
      <div className={styles.header}>
        <div className={styles.left}></div>
        <div className={styles.right}></div>
      </div>
      <img src={logo} className={styles.logo} alt="" />

      <div className={styles.footer}>
        <div className={styles.left}>
          <a
            href="https://www.facebook.com/worldwide.connectionllc"
            target="_blank"
            without
            rel="noopener noreferrer"
          >
            <FacebookOutlined />
          </a>
          <a
            href="https://twitter.com/wwconnect"
            target="_blank"
            without
            rel="noopener noreferrer"
          >
            <TwitterOutlined />
          </a>
        </div>
        <div className={styles.right}>© 2020 WWC, LLC.</div>
      </div>
    </div>
  );
};
