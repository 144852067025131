const defaultOptions = {
  credentials: "include",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8"
  }
};

export default async (url, body, options = { headers: {}, method: "GET" }) => {
  console.log("url", url);
  let _options = {
    ...defaultOptions,
    ...options,
    headers: {
      ...defaultOptions.headers,
      ...options.headers
    }
  };

  if (body) {
    _options = {
      ..._options,
      body: JSON.stringify(body)
    };
  }
  console.log("_options", _options);
  try {
    const response = await fetch(`/api.php/${url}`, _options);
    const json = await response.json();

    return {
      type: "success",
      payload: json.data
    };
  } catch (e) {
    console.log("e :", e);
    return {
      type: "error",
      payload: e
    };
  }
};
