import React from "react";
import styles from "./styles.module.css";
import bg from "../../assets/img/project-shield.jpg";
import { slide as Menu } from "react-burger-menu";
import { request } from "../../utils";

export default () => {
  const [listLoading, setListLoading] = React.useState(false);
  const [current, setCurrent] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [isOpen, setIsopen] = React.useState(false);
  const reload = React.useCallback(async () => {
    setListLoading(true);
    const response = await request(`page/all`);
    if (response.type === "success") {
      setData(response.payload);
    } else {
      setData([]);
    }
    setListLoading(false);
  }, []);

  React.useEffect(() => {
    reload();
  }, [reload]);

  React.useEffect(() => {
    setIsopen(false);
  }, [current]);
  const isMenuOpen = state => {
    setIsopen(state.isOpen);
    return state.isOpen;
  };

  return (
    <>
      <Menu
        burgerButtonClassName={"right-burget"}
        right
        onStateChange={isMenuOpen}
        isOpen={isOpen}
        className={`${styles.menu} extra-burger`}
        pageWrapId={"page-wrap"}
        outerContainerId={"outer-container"}
      >
        {data.map((item, i) => (
          <div
            key={i}
            className={`${styles.navItem} ${
              parseInt(item.parent_id, 10) ? styles.subitem : ""
            }`}
            onClick={() => setCurrent(i)}
          >
            {item.name}
          </div>
        ))}
      </Menu>
      <div
        className={`${styles.about} wwc-content`}
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className={styles.nav}>
          {data.map((item, i) => (
            <div
              key={i}
              className={styles.navItem}
              onClick={() => setCurrent(i)}
            >
              {item.name}
            </div>
          ))}
        </div>

        <div className={styles.content}>
          {data.map((item, i) =>
            current === i ? (
              <React.Fragment key={i}>
                <div className={styles.headline}>{item.name}</div>
                <div
                  className={styles.body}
                  dangerouslySetInnerHTML={{ __html: item.body }}
                />
              </React.Fragment>
            ) : null
          )}
        </div>
      </div>
    </>
  );
};
