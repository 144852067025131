import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

const global = (state = { user: null }, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    global
  });

export default createRootReducer;
