import React from "react";
import { elastic as Menu } from "react-burger-menu";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./styles.module.css";
import logo from "../../assets/img/logo-white.png";

const menus = [
  {
    name: "Бидний тухай",
    url: "/about-us"
  },
  {
    name: "Тээврийн үйлчилгээ",
    url: "/freight-product"
  },
  {
    name: "Мэдээ",
    url: "/news"
  },
  {
    name: "Харилцагчийн булан",
    url: "/partners"
  },
  {
    name: "Тээврийн үнийн санал",
    url: "/freight-pricing"
  },
  {
    name: "Холбоо барих",
    url: "/contact-us"
  }
];

export default () => {
  const location = useLocation();
  const [isOpen, setIsopen] = React.useState(false);
  React.useEffect(() => {
    setIsopen(false);
  }, [location.pathname]);

  const isMenuOpen = state => {
    setIsopen(state.isOpen);
    return state.isOpen;
  };

  return (
    <Menu
      onStateChange={isMenuOpen}
      isOpen={isOpen}
      className={styles.menu}
      pageWrapId={"page-wrap"}
      outerContainerId={"outer-container"}
    >
      <NavLink to="/" className={styles.logo}>
        <img src={logo} width="100%" alt="" />
      </NavLink>
      {menus.map((item, i) => (
        <NavLink className={styles.menuItem} key={i} to={item.url}>
          {item.name}
        </NavLink>
      ))}
    </Menu>
  );
};
