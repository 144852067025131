import React from "react";
import { notification, Button } from "antd";
import { Form, Input, FormItem } from "formik-antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { LoadingOutlined } from "@ant-design/icons";
import styles from "./styles.module.css";
import { request } from "../../utils";

const SignupSchema = Yup.object().shape({
  fromname: Yup.string().required("Таны нэр оруулна уу"),
  text: Yup.string().required("Утасны дугаар оруулна уу"),
  from: Yup.string().required("Зурвас оруулна уу"),
});
export default () => {
  const handleSubmit = async (values, actions) => {
    const response = await request(`public/contact`, values, {
      method: "POST",
    });
    actions.setSubmitting(false);
    if (response.type === "error") {
      return notification.error({
        message: "Алдаа",
        description: "Хүсэлт алдаатай",
      });
    }
    actions.resetForm();

    notification.success({
      message: "Таны саналыг бид хүлээн авлаа мэдээлэлыг шалгаж үзээд эргэн холбогдох болно",
    });
  };
  return (
    <div className={styles.contentWrapper}>
      <div className={styles.inner}>
        <h2>Холбоо барих</h2>
        <Formik
          initialValues={{
            fromname: undefined,
            text: undefined,
            from: undefined,
          }}
          validationSchema={SignupSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form layout={"vertical"}>
              <FormItem name='fromname' label='Таны нэр'>
                <Input name='fromname' />
              </FormItem>
              <FormItem name='from' label='Имэйл хаяг'>
                <Input name='from' />
              </FormItem>
              <FormItem name='text' label='Зурвас'>
                <Input.TextArea name='text' />
              </FormItem>

              <Button icon={isSubmitting ? <LoadingOutlined /> : null} htmlType='submit'>
                Илгээх
              </Button>
            </Form>
          )}
        </Formik>
        <div className={styles.contactText}>
          <div>Баянгол дүүрэг, Рокмон Бюлдинг #201 Улаанбаатар, Монгол улс</div>
          <div>+976 70107172</div>
          <div>info@wwc.mn</div>
        </div>
      </div>
    </div>
  );
};
