import React from "react";
import { Switch, Route } from "react-router-dom";
import "./App.css";
import { SideBar } from "./components";
import {
  AboutUs,
  ContactUs,
  FreightPricing,
  FreightProduct,
  News,
  Partners,
  Home
} from "./pages";

function App() {

  return (
    <div id="outer-container">
      <SideBar />
      <main id="page-wrap">
        <Switch>
          <Route path="/about-us">
            <AboutUs />
          </Route>
          <Route path="/news">
            <News />
          </Route>
          <Route path="/freight-product">
            <FreightProduct />
          </Route>
          <Route path="/freight-pricing">
            <FreightPricing />
          </Route>
          <Route path="/contact-us">
            <ContactUs />
          </Route>
          <Route path="/partners">
            <Partners />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </main>
    </div>
  );
}

export default App;
